import useLogIn from "@hooks/useLogIn";
import { commonReplacements, CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { getLogInValidationSchema } from "@lib/validators/user/logInSchema";
import { Button } from "@ui/components/forms/button/Button";
import { Formik, Form } from "formik";
import type { FC } from "react";
import { useRef, useState } from "react";
import { renderRichTextToComponent } from "@components/cms/rich-text-renderer/renderRichTextToComponent";
import type { Document } from "@contentful/rich-text-types";
import { Box } from "@ui/components/layout/box/Box";
import { FormControl } from "@ui/components/forms/form-control/FormControl";
import { FormLabel } from "@ui/components/forms/form-control/FormLabel";
import { Field } from "@ui/components/forms/field/Field";
import { FormErrorMessage } from "@ui/components/forms/form-control/FormError";
import PasswordField from "@components/primitives/PasswordField";
import { Input } from "@ui/components/forms/input/Input";

interface Props {
    formId: string;
    onSuccess?: () => void;
    onFail?: () => void;
    forgotPasswordText?: Document;
}

const LogInForm: FC<Props> = ({
    formId,
    onSuccess = () => {},
    onFail = () => {},
    forgotPasswordText,
}) => {
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formRef = useRef();
    const onFailLogIn = () => {
        setIsError(true);
        setErrorMessage(headers.errors.emailOrPasswordError);
        onFail();
    };
    const { logIn } = useLogIn(onSuccess, onFailLogIn);
    const { get: getMicrocopy } = useMicrocopy();

    const headers = {
        title: getMicrocopy(CommonCMS.global, CommonCMS.logInTitle),
        email: getMicrocopy(CommonCMS.global, CommonCMS.email),
        password: getMicrocopy(CommonCMS.global, CommonCMS.password),
        logInButton: getMicrocopy(CommonCMS.global, CommonCMS.button.logIn),
        errors: {
            email: getMicrocopy(CommonCMS.global, CommonCMS.error.email),
            emailOrPasswordError: getMicrocopy(
                CommonCMS.global,
                CommonCMS.error.emailOrPasswordError
            ),
            requiredField: getMicrocopy(CommonCMS.global, CommonCMS.error.requiredFieldError),
            maxCharacters: getMicrocopy(CommonCMS.global, CommonCMS.error.maxCharacters, {
                replacements: commonReplacements.maxEmailCharacters,
            }),
        },
    };

    const onSubmit = (values, action) => {
        setIsError(false);
        logIn(values, action);
    };

    return (
        <Formik
            validationSchema={getLogInValidationSchema(headers.errors)}
            initialValues={{
                email: "",
                password: "",
            }}
            innerRef={formRef}
            onSubmit={onSubmit}
        >
            {({ submitForm, errors, isSubmitting, touched, setFieldValue }) => {
                return (
                    <>
                        <Form noValidate={true} id={formId}>
                            <FormControl
                                isRequired
                                isInvalid={(!!errors.email && touched.email) as boolean}
                            >
                                <FormLabel
                                    htmlFor="email"
                                    id="emailLabel"
                                    fontSize={["mobileBodyTextSmall", "desktopBodyTextSmall"]}
                                >
                                    {headers.email}
                                </FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                    aria-labelledby="emailLabel"
                                    onBlur={(e) => {
                                        // Adding an empty space at the end will trigger the setter
                                        // Otherwise the value is considered to be unchanged.
                                        setFieldValue("email", e.target.value.trim() + " ");
                                    }}
                                />
                                <FormErrorMessage>{errors.email?.toString()}</FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isRequired
                                data-privacy="true"
                                isInvalid={(!!errors.password && touched.password) as boolean}
                            >
                                <FormLabel
                                    htmlFor="password"
                                    id="passwordLabel"
                                    fontSize={["mobileBodyTextSmall", "desktopBodyTextSmall"]}
                                >
                                    {headers.password}
                                </FormLabel>
                                <PasswordField id="password" ariaLabelledBy="passwordLabel" />
                                <FormErrorMessage>{errors.password?.toString()}</FormErrorMessage>
                            </FormControl>
                            {isError && (
                                <FormControl isInvalid={isError} my={4}>
                                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                                </FormControl>
                            )}{" "}
                        </Form>
                        <Box
                            my={6}
                            textAlign={"right"}
                            sx={{
                                "& button": {
                                    textDecor: "underline",
                                },
                            }}
                        >
                            {forgotPasswordText && renderRichTextToComponent(forgotPasswordText)}
                        </Box>
                        <Button
                            w={"100%"}
                            type={"submit"}
                            form={formId}
                            isLoading={isSubmitting}
                            onClick={submitForm}
                            data-testid="logInButton"
                        >
                            {headers.logInButton}
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};

export default LogInForm;
