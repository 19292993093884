import type { ShoppingList } from "@graphql/generated/components";
import { useAuth } from "@lib/auth/useAuth";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import {
    addToMyShoppingList,
    deleteMyShoppingList,
} from "@lib/utils/shoppingList-utility/shoppingListUtility";
import { useErrorToast } from "@lib/utils/store-utility/storeUtility";
import { useGetMyActiveData, useRecalculateCart } from "@store/hooks/cartHooks";
import type { StoreState } from "@store/index";
import { useStore, useStoreApi } from "@store/index";
import {
    useDeleteMyWishlist,
    useGetMyActiveShoppingLists,
    useAddProductAndRemoveOldestFromWishlist,
    useAddProductToWishlist,
} from "@store/hooks";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

const useLogIn = (onSuccess, onFail?) => {
    const { locale } = useRouter();
    const store = useStore<StoreState>();
    const storeApi = useStoreApi();
    const { session } = useAuth();
    const { get: getMicrocopy } = useMicrocopy();
    const errorToaster = useErrorToast();
    const executeGetMyActiveData = useGetMyActiveData();
    const executeGetMyActiveWishlists = useGetMyActiveShoppingLists();
    const executeDeleteWishlist = useDeleteMyWishlist();
    const executeAddProductToWishlist = useAddProductToWishlist();
    const executeAddProductAndRemoveOldestFromWishlist = useAddProductAndRemoveOldestFromWishlist();
    const recalculateCart = useRecalculateCart();

    async function handleMergingCustomerShoppingLists(
        shoppingLists: ShoppingList[]
    ): Promise<void> {
        const userItems = shoppingLists?.[0]?.lineItems;
        const anonymousItems = shoppingLists?.slice(1);

        const anonymousLineItems = anonymousItems?.map(({ lineItems }) => lineItems).flat();

        if (!anonymousLineItems?.length) return;

        for (const lineItem of anonymousLineItems) {
            const { wishlist } = storeApi.getState().data.me.wishlistInfo;

            const foundItem = userItems.find(({ productId }) => productId === lineItem.productId);
            const shouldAddToWishlist = !foundItem && lineItem.variant?.sku;

            if (shouldAddToWishlist) {
                await addToMyShoppingList(
                    locale,
                    Array.isArray(wishlist) ? wishlist[0] : wishlist,
                    lineItem.variant.sku,
                    executeAddProductToWishlist,
                    executeAddProductAndRemoveOldestFromWishlist,
                    lineItem.quantity
                );
            }
        }

        for (const list of anonymousItems) {
            await deleteMyShoppingList(
                locale,
                list,
                getMicrocopy,
                executeDeleteWishlist,
                errorToaster
            );
        }
    }

    async function logIn(values, actions): Promise<void> {
        const result = await signIn("authenticated", {
            redirect: false,
            email: values["email"].trim(),
            password: values["password"],
            locale: locale,
            anonymousId: session?.anonymousId,
        });
        const onSignInSuccess = async () => {
            store.setMasterProducts(null);
            store.setIsLoggedIn(true);
            store.setWishlistMasterProducts(null);
            const { data } = await executeGetMyActiveWishlists({});
            await handleMergingCustomerShoppingLists(data?.me?.shoppingLists?.results);
            await executeGetMyActiveData({});
            const newCart = storeApi.getState().data.me.cartInfo.cart;
            if (newCart)
                await recalculateCart({
                    cartId: newCart.id,
                    cartVersion: newCart.version,
                    storeKey: newCart.store.key,
                    locale: locale,
                });
            onSuccess();
            window.ometria?.identify(values["email"]);
            actions.setSubmitting(false);
        };
        if (!result.error) {
            onSignInSuccess();
            window.ometria?.identify(values["email"]);
            actions.setSubmitting(false);
            return;
        }

        onFail(result.status);
        actions.setSubmitting(false);
    }
    return { logIn: logIn };
};

export default useLogIn;
