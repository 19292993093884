import type { FC } from "react";
import { useRouter } from "next/router";
import { Text } from "@ui/components/content/text/Text";
import ProsList from "@components/ui/pros-list/ProsList";
import { Button } from "@ui/components/forms/button/Button";
import { CommonCMS, Auth } from "@lib/constants/contentful";
import type { BoxProps } from "@ui/components/layout/box/Box";
import { Box } from "@ui/components/layout/box/Box";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { createAccountPage, getPagePathByLocale } from "@lib/constants/pagePaths";

const CreateAccountInvitation: FC<BoxProps> = ({ ...otherProps }) => {
    const { getMultiple } = useMicrocopy();
    const { locale } = useRouter();

    const usps = getMultiple(CommonCMS.page, CommonCMS.usps);
    const microcopies = (() => {
        return getMultiple(CommonCMS.page, [
            Auth.createAccountInvitation,
            CommonCMS.button.createAccount,
        ]);
    })();
    return (
        <Box
            sx={{
                mt: [2, 2, 2, 0],
                py: 6,
                px: [3, 4, 4, 6],
                bg: "white",
            }}
            {...otherProps}
        >
            <Text
                fontSize={["mobileHeading3", "desktopHeading5"]}
                fontWeight={"semibold"}
                mb={6}
                textTransform="uppercase"
            >
                {microcopies[Auth.createAccountInvitation]}
            </Text>
            <ProsList usps={usps} />
            <Button
                as={NextLink}
                href={getPagePathByLocale(createAccountPage, locale)}
                variant={"solid"}
                textDecoration={"none"}
                mt={8}
            >
                <span>{microcopies[CommonCMS.button.createAccount]}</span>
            </Button>
        </Box>
    );
};

export default CreateAccountInvitation;
