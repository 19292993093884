import type { FC } from "react";
import { isEmpty } from "lodash-es";
import { ECCOIcon } from "@ui/components/media-and-icons/ecco-icon/ECCOIcon";
import { Text } from "@ui/components/content/text/Text";
import { Flex } from "@ui/components/layout/flex/Flex";

interface Props {
    usps: object;
}

const ProsList: FC<Props> = ({ usps }) => {
    if (isEmpty(usps)) return null;

    return (
        <>
            {Object?.values(usps)?.map((element) => {
                return (
                    <Flex key={element} gap={4} mt={3}>
                        <ECCOIcon name={"ok"} size={"sm"} />
                        <Text
                            lineHeight={"base"}
                            fontWeight={"semibold"}
                            fontSize={["mobileBodyTextSmall", "desktopBodyTextTiny"]}
                        >
                            {element}
                        </Text>
                    </Flex>
                );
            })}
        </>
    );
};

export default ProsList;
