import AuthView from "@components/auth/AuthView";
import CreateAccountInvitation from "@components/auth/auth-invitation/CreateAccountInvitation";
import LogInForm from "@components/auth/login/LogInForm";
import { PageSeo } from "@components/seo/PageSeo";
import type { ISiteSettings } from "@contentful-api/types/contentful";
import type { NextPageWithLayout } from "@interfaces/PageInterfaces";
import { CommonCMS } from "@lib/constants/contentful";
import { getPagePathByLocale, logInPage, myAccountPage } from "@lib/constants/pagePaths";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { getDefaultLayoutWithGrayBody } from "@lib/utils/layoutUtility";
import { Heading } from "@ui/components/content/heading/Heading";
import { Box } from "@ui/components/layout/box/Box";
import { Grid, GridItem } from "@ui/components/layout/grid/Grid";
import { deserialize } from "@ungap/structured-clone";
import type { GetStaticPropsContext, PreviewData } from "next";
import { useRouter } from "next/router";
import type { ParsedUrlQuery } from "querystring";
import type { AuthorizationPageProps } from "templates/AuthorizationPage";
import { generateGetStaticPropsForAuthorizationPage } from "templates/AuthorizationPage";

const LogInPage: NextPageWithLayout<AuthorizationPageProps> = ({
    serializedSiteSettings,
    pathName,
}) => {
    const siteSettings: ISiteSettings = deserialize(serializedSiteSettings);
    const seoMetadata = siteSettings?.fields?.seoMetadata;
    const forgotPassword = siteSettings?.fields.forgotYourPassword;

    const { get: getMicrocopy } = useMicrocopy();
    const { push, locale } = useRouter();
    const onLogIn = () => push(getPagePathByLocale(myAccountPage, locale));

    return (
        <>
            {seoMetadata && <PageSeo seoModule={seoMetadata} pathName={pathName} />}{" "}
            <div hidden aria-hidden="true" data-testid="logInPage" />
            <Heading
                as="h1"
                textTransform="capitalize"
                pb={[6, 12]}
                mt={[5, 8]}
                ml={["layoutMargin.mobile", "layoutMargin.tab", "layoutMargin.desktop"]}
            >
                {getMicrocopy(CommonCMS.global, CommonCMS.logInTitle)}
            </Heading>
            <Grid
                as="main"
                data-testid="logInPage"
                mb={8}
                gap={0}
                gridTemplateColumns={["100%", "100%", "100%", "2fr 1fr"]}
            >
                <GridItem>
                    <Box px={[3, 4, 6]} pr={[3, 4, 6, 0]}>
                        <AuthView titleKey="logInTitle">
                            <LogInForm
                                formId="logInForm"
                                onSuccess={onLogIn}
                                forgotPasswordText={forgotPassword}
                            />
                        </AuthView>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box w="100%" px={[3, 4, 6]} pl={[3, 4, 6, 0.2]}>
                        <CreateAccountInvitation />
                    </Box>
                </GridItem>
            </Grid>
        </>
    );
};

export default LogInPage;

LogInPage.getLayout = getDefaultLayoutWithGrayBody;

export const getServerSideProps = async (
    context: GetStaticPropsContext<ParsedUrlQuery, PreviewData>
) => {
    return generateGetStaticPropsForAuthorizationPage(context, logInPage);
};
